/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/@cat-digital-workspace/shared-ui-core/themes.scss"; 

.app-cat-theme {
  $themeConfig: null;
  $typograpphyConfig: null;
  $isLoadDefaultTypography: true;
  @include registertheme($themeConfig,
    $typograpphyConfig,
    $isLoadDefaultTypography,'','white-2.0',$componentslist: (
    'sidenav': 'false',
    'popover': 'false',
    'topnav-white': 'false',
    'topnav-dark': 'false',
    'table': 'true',
    'pagination': 'false',
    'filepicker': 'false',
    'contextual-menu': 'false',
    'dropdown': 'true',
    'cc-icon': 'false',
    'tab': 'false',
    'menu': 'false',
    'list': 'true',
    'drawer': 'false',
    'modal': 'false',
    'radio-button': 'false',
    'chips': 'false',
    'accordion': 'false',
    'slide': 'false',
    'checkbox': 'true',
    'progress-tracker': 'false',
    'stepper': 'false',
    'message': 'false',
    'timepicker': 'false',
    'input-field': 'false',
    'datepicker': 'false',
    'status-indicator': 'false',
    'datecard': 'false',
    'file-upload': 'false',
    'toast-message': 'false',
    'button': 'false',
  ));

  .rc-card-no-shadow {
    .cc-card-container {
      box-shadow: none;
    }
  }

  .training-resource-edit-input .cc-input-field .cc-input-inner-container {
    position: relative;
    padding: 0px;
    margin-bottom: 30px;
  }

  .rc-modal-component {
    .cc-modal-content {
      padding: 0px !important;
    }

    .cc-modal-header {
      padding: 15px 30px !important;
    }

    .cc-modal-footer {
      padding: 0px;
      padding-top: 15px;
      padding-bottom: 10px;

      button {
        margin-left: 0px;
      }
    }
  }
  
}

.quill-editor-no-border .ql-container.ql-snow {
  height: auto;
}

.quill-editor-placeholder .ql-editor p {
  font-style: normal;
}

.mat-sort-header-position-before .mat-sort-header-arrow,
[dir="rtl"] .mat-sort-header-arrow {
  margin: 0 -6px 0 0 !important;
}

body {
  background-color: #f6f6fa;
  overflow: auto;
  margin: 0;
  height: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  z-index: 0;
  letter-spacing: 0;
}

@media screen and (min-width: 769px) {
  // .modal-dialog{
  //     max-width:none !important;
  //     top:20%;
  // }
  // .modal-content{
  //     border-radius: 0.6rem !important;
  //     background-color:transparent !important;
  //     border:none;
  //     align-items: center;
  // }
}

@media screen and (max-width: 768px) {
  .filter-popover {
    display: none;
  }
}

//---------------------------- drang and drop style starts --------------------------
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

.print {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.print-icon {
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.privacy-policy-ml {
  margin-left: 41px;
}

.text-justify-align {
  text-align: justify;
}



.heading-content-ml {
  margin-left: 30px;
}

.child-heading-num-ml {
  margin-left: 45px;
}

.child-heading-content-ml {
  margin-left: 25px;
}

.sub-child-heading-num-ml {
  margin-left: 90px;
}

.sub-child-heading-content-ml {
  margin-left: 10px;
}

.text-center-align {
  text-align: center;
}

.exhibit li {
  list-style: none;
}

tr.gu-mirror {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

//---------------------------- drang and drop style ends --------------------------
.eye-popover {
  top: -30px !important;
  width: 230px;
  max-height: 65vh;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 99999 !important;
  padding-top: 10px;

  div.arrow::before {
    top: 31px !important;
  }

  div.arrow::after {
    top: 31px !important;
  }
}


.filter-popover {
  top: 0px !important;
  width: auto;

  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 550px;

  .popover {
    max-width: auto;
  }

  div.arrow::before {
    top: 31px !important;
    border-style: none;
  }

  div.arrow::after {
    top: 31px !important;
    border-style: none;
  }
}

// UI Redesign changes
.categoryToggleBtn {
  max-width: 6px;

  .collapsed {
    em {
      transform: rotateZ(90deg) !important;
    }
  }

  em {
    transform: rotateZ(-90deg) !important;
  }
}

// UI Redesign changes
// UI Redesign- for preview

#accordPricing.accordion .card {
  margin-bottom: 1rem !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0px;
}

#accordPricing.card-header {
  padding: 0.5rem 1.25rem !important;
}

#accordPricing.accordion .card .card-header {
  background-color: #848484;
  border-radius: 0px;
}

.hover-class {
  max-width: 700px;
  pointer-events: none;

  .popover-body {
    width: 700px;
    padding: 0.25rem !important;
  }
}

.pricing-hover {
  max-width: 500px;
z-index: 6000;
  .popover-body {
    width: 500px;
  }
}

.filter-popovers {
  width: 285px !important;
  max-width: 300px !important;

  div.arrow::before {
    top: 31px !important;
    border-style: none !important;
  }

  div.arrow::after {
    top: 31px !important;
    border-style: none !important;
  }
}

.service-loading-bar {
  .bar {
    height: 4px !important;
    // margin-top: 46px;
  }

  #loading-bar-spinner {

    // color: #212529 !important;
    // margin-top: 50px;
    .spinner-icon {
      display: none;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: fit-content !important;
  }

  .modal-content {
    border-radius: 0.6rem !important;
    border: none;
  }

  @supports (-ms-ime-align: auto) {
    .modal-dialog {
      display: flex;
      justify-content: center;
    }

    .modal-content {
      display: table !important;
    }
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    .modal-dialog {
      display: flex;
      justify-content: center;
    }

    .modal-content {
      display: table !important;
    }
  }
}

@supports (-ms-ime-align: auto) {
  .confirm-modal .modal-dialog .modal-content {
    width: 400px !important;
  }

  .engine-perform .modal-dialog .modal-content {
    width: 850px !important;
  }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .labour-popup .modal-dialog .modal-content {
    left: -20%;
  }

  .travel-popup .modal-dialog .modal-content,
   .modal-dialog .modal-content,
  .smcs-popup .modal-dialog .modal-content,
  .product-popup .modal-dialog .modal-content {
    left: -25%;
  }

  .customer-popup {
    left: -25%;
    z-index: 2000 !important;
  }

  .job-edit-popup .modal-dialog .modal-content {
    left: -30%;
  }

  .engine-perform .modal-dialog .modal-content {
    left: -36%;
  }
}

//........... parts header style starts.............
.partsMainHeader {
  div.card {
    margin: 0px !important;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom: none !important;
  }

  .card-header {
    background-color: white;
    padding-left: 10px;
  }

  .t3 {
    top: 3px;
  }

  .toggleBtn {
    max-width: 6px;

    .collapsed {
      em {
        transform: rotateZ(0deg);
      }
    }

    em {
      transform: rotateZ(90deg);
    }
  }

  button:focus {
    box-shadow: none;
  }

  .card-body {
    padding: 0px;
  }

  .card:last-child {
    .card-header {
      margin-bottom: 0px;
    }

    .card-body {
      margin-top: -1px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
}

//........... parts header style ends.............

html {
  height: 100%;
}

.partsHeaderHierarchy {
  div.card {
    margin: 0px !important;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom: none !important;
  }

  .card-header {
    background-color: white;
    padding-left: 10px;
  }

  .t3 {
    top: 3px;
  }

  .toggleBtn {
    max-width: 6px;

    .collapsed {
      em {
        transform: rotateZ(0deg);
      }
    }

    em {
      transform: rotateZ(90deg);
    }
  }

  button:focus {
    box-shadow: none;
  }

  .card-body {
    padding: 0px;
  }

  .card:last-child {
    .card-header {
      margin-bottom: 0px;
    }

    .card-body {
      margin-top: -1px;
      border-bottom: 0 solid rgba(0, 0, 0, 0.125);
    }
  }

  .card:not(:last-child) {
    .card {
      margin-bottom: 0 !important;
      border-left: 0px solid rgba(0, 0, 0, 0.125) !important;
      border-right: 0px solid rgba(0, 0, 0, 0.125) !important;
      border-bottom: none !important;
    }

    .card-header {
      margin-bottom: -1px;
    }
  }
}

.mat-option {
  color: black;
  font-size: 13px !important;
}

.mat-option.mat-selected {
  color: #fff !important;
  background: #43485c !important;
}

.mat-option:hover:not(.mat-option-disabled) {
  background: #43485c;
  color: #fff;
}

.mat-option.mat-active {
  color: black;
  background: #fff;
}

.pagination-select-panel {
  min-width: calc(100% + 20px) !important;
}

.mat-form-field.mat-focused {
  .mat-form-field-wrapper {
    .mat-form-field-ripple {
      background-color: #ffcc00 !important;
    }
  }
}

.field-class {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-placeholder {
                font-size: 14px;
              }

              .mat-select-value-text {
                font-size: 13px;
                font-weight: 400;
                color: #43485c;
                text-shadow: none;
              }
            }
          }
        }

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            mat-label {
              font-size: 14px;
              color: #333333;
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      background-color: #444343 !important;
    }
  }
}

.field-class.currency-dropdown {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-placeholder {
                font-size: 14px;
              }

              .mat-select-value-text {
                font-size: 13px;

                font-weight: unset;

                color: black;

                text-shadow: none;
              }
            }
          }
        }

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            mat-label {
              font-size: 14px;

              color: #333333;
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      background-color: #444343 !important;
    }
  }
}

.field-class.work-group-select.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            transform: translate(0, -20px) scale(1);
            width: fit-content !important;

            mat-label {
              font-size: 14px;
              color: #43485c;
              font-weight: unset;
            }
          }

          top: -20px !important;
        }

        padding: 8px 0 0.5375rem 0;
      }

      .mat-select {
        .mat-select-trigger {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: black;
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      background-color: #444343;
    }
  }
}

.work-group {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-placeholder {
                font-size: 14px;
              }

              .mat-select-value-text {
                font-size: 13px;
                font-weight: bold;
                // color: #43485c;
                color: #c5cae9;
                text-shadow: none;
              }
            }
          }
        }

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            mat-label {
              font-size: 13px;
              color: #333333;
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      background-color: #444343;
    }
  }
}

.field-class.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            transform: translate(0, -20px) scale(1);

            mat-label {
              font-size: 14px;
              color: #43485c;
              font-weight: unset;
            }
          }

          top: -20px !important;
        }

        padding: 8px 0 0.5375rem 0;
      }

      .mat-select {
        .mat-select-trigger {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: black;
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      background-color: #444343;
    }
  }
}

.multi .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #ffcc00 !important;
}

.multi {
  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox::after {
    color: #000000 !important;
  }
}

.work-group.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            transform: translate(0, -20px) scale(1);

            mat-label {
              font-size: 13px;
              color: #43485c;
              // color: white;
              font-weight: unset;
            }
          }

          top: -20px !important;
        }

        padding: 8px 0 0.5375rem 0;
      }

      .mat-select {
        .mat-select-trigger {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              // color:black;
              color: #c5cae9;
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      background-color: #444343;
    }
  }
}

.created-Date {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border-bottom: 1px solid #444343;
    }

    .mat-form-field-infix {
      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          // transform: translate(0, -20px) scale(1);
          // mat-label{
          //   font-size:14px;
          color: #43485c;
          //   font-family:"Roboto-Regular";
          //   font-weight: unset;
          // }
        }

        // top:-20px !important;
      }

      // padding: 8px 0 .5375rem 0;
    }
  }
}

.created-Date.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            transform: translate(0, -20px) scale(1);

            mat-label {
              font-size: 13px;
              color: #43485c;
              font-weight: unset;
            }
          }

          top: -20px !important;
        }

        padding: 8px 0 0.5375rem 0;
      }
    }
  }
}

.field-class.mat-focused {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-select {
        .mat-select-trigger {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: black !important;
            }
          }
        }
      }
    }
  }
}

.work-group.mat-focused {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-select {
        .mat-select-trigger {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: #c5cae9;
            }
          }
        }
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.mat-form-field {
  width: 100%;
}

.newClass {
  .mat-select-trigger {
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: black;
      }
    }
  }
}

.confirm-modal {
  z-index: 9999999 !important;
}

.confirm-modal-backdrop {
  z-index: 9999999 !important;
}

.custom-modal .modal-dialog {
  padding: 0 !important;
  border-radius: 0px !important;
  overflow-y: hidden;

  width: 100%;
  height: 100%;
}

.field-class.mat-form-field-disabled {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            mat-label {
              color: #7a7f90;
            }
          }
        }

        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                color: #808392;
              }
            }
          }
        }
      }
    }
  }
}

.field-class.paginator-class {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        border-top: 0px !important;
      }
    }
  }
}

.edit-estimate-popover-class {
  z-index: 9999 !important;
}

.edit-estimate-backdrop {
  z-index: 9999 !important;
}

// .cdk-global-overlay-wrapper {
//   z-index: 9999999999 !important;
// }

@supports (-ms-ime-align: auto) {
  .modal-content {
    width: 0px;
  }
}

.example-box.cdk-drag-preview {
  z-index: 9999 !important;
}

.pagination-tag {
  width: 100%;
}

.paginator-class:hover {
  .mat-form-field-wrapper {
    .mat-form-field-underline {
      background-color: #ffc000;
      height: 2px;
    }
  }
}

.additional-class:hover {
  .mat-form-field-wrapper {
    .mat-form-field-underline {
      background-color: #ffc000 !important;
      height: 2px !important;
    }
  }
}

.additional-class {
  &.ng-invalid.ng-touched {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              mat-label {
                color: #dc3545;
              }
            }
          }
        }
      }
    }
  }
}

.mat-select-panel.pagination-select-panel {
  margin-bottom: 20px;
}

.date-picker-class {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            font-size: 14px;
            color: #43485c;
          }
        }

        .mat-input-element {
          caret-color: #43485c;
        }
      }
    }
  }
}

.mat-calendar-body-selected {
  background-color: #ffcc00;
  color: black;
}

.mat-datepicker-toggle-active {
  color: #ffcc00;
}

// .partsHeaderHierarchy .card:last-child .card-body{
//   color:red;
// }

// .partsHeaderHierarchy .card:last-child {
//   border-left:0px;
//           border-right:0px;
// }

::-ms-clear {
  display: none;
}

.field-class.estimate-workgroup-selector {
  width: 210px;

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        border-top: 0px !important;

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            mat-label {
              font-style: italic;
            }
          }
        }
      }

      background-color: white;
      padding-left: 10px;
      padding-right: 5px;
    }
  }
}

.work-group.estimate-workgroup-selector {
  width: 210px;

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        border-top: 0px !important;

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            mat-label {
              font-style: italic;
              color: #c5cae9;
            }
          }
        }
      }

      // background-color: white;
      background-color: #000000 !important;
      padding-left: 10px;
      padding-right: 5px;
    }
  }
}

.mat-form-field-label-wrapper {
  overflow: visible !important;
}

.field-class.estimate-workgroup-selector.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          display: none;
        }
      }
    }
  }
}

.work-group.estimate-workgroup-selector.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          display: none;
        }
      }
    }
  }
}

.status-color-green {
  color: #36a000 !important;
}

.status-color-yellow {
  color: #ffcc00 !important;
}

.status-color-red {
  color: #dc3545 !important;
}

//Date Range Styles

:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}

:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-semi-selected,
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-semi-selected,
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-semi-selected {
  background-color: #ffcc00;
  color: white;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
  border-radius: 100% 0 0 100%;
  background-color: #c5cae9;
}

.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
  border-radius: 0 100% 100% 0;
  background-color: #c5cae9;
}

.mat-calendar-body>tr .mat-calendar-cell-semi-selected~.mat-calendar-cell-semi-selected {
  border-radius: 0;
}

.mat-calendar-cell-semi-selected {
  background-color: #c5cae9;
}

.mat-calendar-cell-over>.mat-calendar-body-cell-content {
  border: #ffcc00 1px solid;
}

.field-class.ng-invalid {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            mat-label.select-error-class {
              color: #dc3545 !important;
            }
          }
        }
      }
    }
  }
}

//....... for popup loading indicator starts
.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

//....... for popup loading indicator ends

.status-snack-bar {
  background-color: #ffcc00;
  color: black;
  font-weight: 600;

  margin-bottom: 4rem !important;

  .mat-simple-snackbar {
    justify-content: center !important;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 900px) {
  .field-class.estimate-workgroup-selector {
    width: 132px !important;
  }
}

.progress-spinner-modal {
  .modal-dialog {
    .modal-content {
      background-color: unset !important;
    }
  }
}

@supports (-ms-ime-align: auto) {
  .progress-spinner-modal {
    .modal-dialog {
      .modal-content {
        width: 100%;
        background: none !important;
      }
    }
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: white;
}

.edit-estimate-spinner {
  z-index: 100000 !important;
}
.progress-spinner-edit-estimate {
  z-index: 100000 !important;
}

.my-custom-class .tooltip-inner {
  width: 153px;
  max-width: 300px;
  padding: 0.25rem 0.5rem;
  color: #000;
  text-align: center;
  background-color: #ffcc00 !important;
  border-radius: 0.25rem;
  font-weight: 500;
}

.my-custom-class .arrow::before {
  border-top-color: #ffcc00;
}

.split-btn-primary {
  border-radius: 0px !important;
  border: none !important;
  font-size: 0.875rem;
  transition: background 200ms ease-out, color 200ms ease-out,
    border 200ms ease-out;
  font-weight: bold;

  .split-button-1,
  .split-button-2 {
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
  }

  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        line-height: 0 !important;
        padding: 0px !important;
      }

      height: 35px;
    }

    .mat-button-toggle-button:focus {
      outline: none !important;
    }
  }

  .split-button-1 {
    padding: 0px 23px;
  }
}

.split-btn-primary:not(:disabled):hover {
  -webkit-box-shadow: 2px 7px 12px #e2c866;
  -moz-box-shadow: 2px 7px 12px #e2c866;
  box-shadow: 2px 7px 12px #e2c866;
}

.split-btn-secondary:not(:disabled):hover {
  -webkit-box-shadow: 2px 7px 12px #707070;
  -moz-box-shadow: 2px 7px 12px #707070;
  box-shadow: 2px 7px 12px #707070;
}

.split-btn-secondary {
  border-radius: 0px !important;
  border: none !important;
  font-size: 0.875rem;
  transition: background 200ms ease-out, color 200ms ease-out,
    border 200ms ease-out;
  font-weight: bold;

  .split-button-1,
  .split-button-2 {
    background-color: #565656;
    border: 1px solid #565656;
    color: #fff;
  }

  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        line-height: 0 !important;
        padding: 0px !important;
      }

      height: 35px;
    }

    .mat-button-toggle-button:focus {
      outline: none !important;
    }
  }

  .split-button-1 {
    padding: 0px 23px;
  }
}

.custom-modalbox {
  mat-dialog-container {
    padding: 0 !important;
    border-radius: 0px !important;
    overflow-y: hidden;

    width: 100%;
    height: 100%;
  }
}
.failedcustom-modalbox{
  mat-dialog-container {
    padding: 0 !important;
    border-radius: 0px !important;
    overflow-y: hidden;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

.save-menu-dropdown {
  min-width: 136px !important;
  min-height: 45px !important;

  .mat-menu-content {
    padding: 0px !important;

    .mat-menu-item {
      height: 45px !important;
      line-height: 42px !important;
      font-weight: bold;
    }

    .mat-menu-item:focus {
      outline: none !important;
    }

    .mat-menu-item:hover {
      color: #fff;
      background-color: #565656;
    }
  }
}

.input-background {
  .cc-input-inner-container {
    .input-field {
      //background-color: #f5f5f5 !important;
      background-color: #f6f6fa !important;
    }
  }
}

.grief-component-backdrop {
  z-index: 999 !important;
}

.grief-component-class {
  z-index: 999;
}

.travel-class {
  .travel-component-tag {
    .travel-container {
      color: red;
      padding-top: 0px !important;

      .heading-class {
        margin-top: 0px !important;
      }
    }
  }
}

.labor-adv-screen {
  .labor-data-table {
    .table-responsive {
      height: fit-content;
    }
  }
}

.adjmt-adv-screen {
  .adjustment-data-table {
    .table-responsive {
      height: fit-content;
    }
  }
}

.add-edit-popup {
  z-index: 9999 !important;
}

.admin-modal-backdrop {
  z-index: 99999 !important;
}

.mat-sort-header-button {
  outline: 0 !important;
}

//mat Dialog custom style for admin
.custom-mat-dialog {
  mat-dialog-container {
    border-radius: 0px !important;
    padding: 0px !important;
  }
}

@media screen and (max-width: 1024px) {
  .work-group.estimate-workgroup-selector {
    width: 140px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .work-group.estimate-workgroup-selector {
    width: 158px !important;
  }
}

@media screen and (min-width: 1260px) and (max-width: 1270px) {
  .work-group.estimate-workgroup-selector {
    width: 180px !important;
  }
}

@media screen and (max-width: 1024px) {
  .work-group.workgroup-selection-class {
    width: 130px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .work-group.workgroup-selection-class {
    width: 138px !important;
  }
}

@media screen and (min-width: 1260px) and (max-width: 1270px) {
  .work-group.workgroup-selection-class {
    width: 160px !important;
  }
}

.work-group.workgroup-selection-class {
  width: 195px;
}

.modal-content {
  border-radius: 0 !important;
  background-clip: unset !important;
}

.prfmce-adv-screen {
  .engine-performance-table {
    .table-responsive {
      height: fit-content !important;
    }
  }
}

.change-background-for-disabled.cc-input-field-disabled {
  .cc-input-inner-container {
    background-color: #ffffff !important;

    .input-field {
      background-color: #ffffff;
    }
  }
}

.solid-underline.mat-form-field-disabled {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      cursor: not-allowed;

      .mat-form-field-infix {
        cursor: not-allowed;

        .mat-select-disabled {
          cursor: not-allowed;

          .mat-select-trigger {
            cursor: not-allowed;
          }
        }
      }
    }

    .mat-form-field-underline {
      background-color: #43485c !important;
      opacity: 0.8;
    }
  }
}

.field-class.currency-dropdown.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          padding-top: 19px;
        }

        .mat-form-field-label-wrapper {
          top: -9px !important;
        }
      }
    }
  }
}

.field-class.currency-dropdown.ng-invalid {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          padding-top: 23px;
        }

        .mat-form-field-label-wrapper {
          top: 10px !important;
        }
      }
    }
  }
}

.estimate-name-field.cc-input-field {
  .cc-input-inner-container {
    .input-field {
      padding-bottom: 5px !important;
    }
  }
}

.profile-name-field.cc-input-field {
  .cc-input-inner-container {
    .input-field {
      padding-bottom: 5px !important;
    }
  }
}

.parts-list-eye-icon {
  width: 230px;
  max-height: 45vh;
  overflow-x: hidden;
  transform: translate(1030px, 172px) !important;
 
}

.parts-header-table {
  .parts-header-data-table {
    .table-responsive {
      height: auto;

      thead {
        tr {
          background-color: #ffffff !important;

          th:not(.border-right-style) {
            color: black !important;
            background-color: #ffffff !important;
          }

          th:hover {
            background: #e6f4ff !important;
            color: black !important;
          }

          th.eye-icon-class {
            .eye-icon {
              color: black !important;
              border: 1px solid !important;
            }
          }

          th.eye-icon-class:hover {
            background: #ffffff !important;
          }
        }
      }
    }
  }
}

.add-edit-engine-perfm {
  .field-class {
    .mat-form-field-wrapper {
      padding-bottom: 15px !important;

      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-select {
            .mat-select-trigger {
              .mat-select-value {
                padding-left: 4px;
              }
            }
          }
        }
      }
    }
  }
}

.hours-adjustment-section .hour-adjustment-table .table-responsive {
  height: 194px !important;
}

.existing-pds-section .existing-pds-table .table-responsive {
  height: fit-content;
}

.mat-tooltip {
  // background-color: darkblue;
  font-size: 12px !important;
}

.mat-form-field.filter-dropdown.mat-focused {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              .mat-select-arrow {
                color: #000000 !important;
              }
            }
          }
        }
      }
    }
  }
}

.search_content {
  .date-style {
    mat-form-field {
      .mat-form-field-infix {
        margin-top: 6px;
      }
    }
  }

  .status_style {
    mat-form-field {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            margin-top: 6px;
          }
        }
      }
    }
  }
}

.hide-button-tooltip {
  left: 5px !important;

  .arrow {
    display: none !important;
  }

  .tooltip-inner {
    width: 100px !important;
  }
}

.filter-popovers.version-popover {
  top: -20px !important;
}

.scheduleDetails-table {
  .table-responsive {
    height: fit-content !important;
    max-height: 40vh !important;
  }
}

.repairSchedule-table {
  .table-responsive {
    height: fit-content !important;
    max-height: 40vh !important;
  }
}

//....... for popup loading indicator starts
.loading-grey:after {
  content: " .";
  animation: dotsGrey 1s steps(5, end) infinite;
}

@keyframes dotsGrey {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

//....... for popup loading indicator ends

.workgroup-list.mat-select-disabled {
  .mat-select-trigger {
    .mat-select-value {
      .mat-select-value-text {
        cursor: not-allowed !important;
      }
    }

    .mat-select-arrow-wrapper {
      cursor: not-allowed !important;
    }
  }
}

.userround:disabled {
  cursor: not-allowed !important;
}

.data-table-height-40vh {
  .table-responsive {
    height: fit-content !important;
    max-height: 40vh !important;
  }
}

.backColor {
  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              mat-label {
                font-weight: 700;
                color: #43485c;
              }
            }
          }
        }
      }
    }
  }
}

.travel-mat-select {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                // word-spacing: 80px;
                word-spacing: 3vw;
              }
            }
          }
        }
      }
    }
  }
}

.coolant-mat-select {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                // word-spacing: 80px;
                word-spacing: 1.7vw;
              }
            }
          }
        }
      }
    }
  }
}

.dropdown-disabled .mat-select-disabled .mat-select-value {
  opacity: 0.8;
  color: #43485c;
  cursor: not-allowed !important;
}

.dropdown-disabled .mat-select-disabled .mat-select-arrow-wrapper {
  opacity: 0.8;
  color: #43485c;
  cursor: not-allowed !important;
}

// @media only screen and (max-width : 1024px)  {
//   .travel-mat-select{
//     .mat-form-field-wrapper{
//       .mat-form-field-flex{
//         .mat-form-field-infix{
//           .mat-select{
//             .mat-select-trigger{
//               .mat-select-value{
//                 .mat-select-value-text{
//                   word-spacing: 6px !important;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

.travel-settings-mat-select {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                word-spacing: 4.5vw;
              }
            }
          }
        }
      }
    }
  }
}

.basic-details-mat-select {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                word-spacing: 2.5vw;
              }
            }
          }
        }
      }
    }
  }
}

.job-details-mat-select {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                word-spacing: 1.2vw;
              }
            }
          }
        }
      }
    }
  }
}

.input-field-no-background.cc-input-field-disabled {
  .cc-input-inner-container.cc-input-field-disabled {
    background-color: unset !important;

    .input-field:disabled {
      background-color: unset !important;
    }

    .cc-input-field-label {
      background-color: unset !important;
    }
  }
}

.travel-mat-popup .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text {
  word-spacing: 30px;
}

.confirm-modal-engine {
  z-index: 99999 !important;
}

.confirm-modal-dealer {
  z-index: 9999999 !important;
}

.avg-load-input.cc-input-field {
  .cc-input-inner-container {
    .cc-input-field-label {
      font-weight: bold;
      color: black;
    }
  }

  .cc-input-inner-container.cc-input-field-disabled {
    .cc-input-field-label {
      opacity: 0.6;
    }
  }
}

.input-background-contingency {
  .cc-input-inner-container {
    .input-field {
      background-color: #f5f5f5 !important;
    }
  }
}

.admin-data-table {
  .mat-header-row {
    .mat-header-cell {
      .mat-sort-header-container .mat-sort-header-arrow {
        opacity: 0.3 !important;
        transform: translateY(0px) !important;
        display: none;
      }
    }
  }
}

.userRequest-data-table {
  .mat-header-row {
    .mat-header-cell {
      .mat-sort-header-container .mat-sort-header-arrow {
        opacity: 0.3 !important;
        transform: translateY(0px) !important;
        display: none;
      }
    }
  }
}

.restore-data-table {
  .mat-header-row {
    .mat-header-cell {
      .mat-sort-header-container .mat-sort-header-arrow {
        opacity: 0.3 !important;
        transform: translateY(0px) !important;
        color: #1991eb !important;
        display: none;
      }
    }
  }
}

.admin-select-dropdown.mat-select-panel {
  max-height: 200px !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .report-select-dropdown.mat-select-panel {
    max-height: 300px !important;
  }
}

.headerdrop {
  .mat-form-field {
    .mat-form-field-wrapper {
      height: 50px;
      line-height: 25px;

      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-select {
            .mat-select-trigger {
              .mat-select-value {
                text-align: right;
              }
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.menu-wraper {
  width: 100% !important;
  max-width: 430px !important;
}

//Style for Settings tab popover
.tab-popover {
  .popover-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .arrow {
    display: none !important;
  }

  border-radius: 0px !important;
  position: absolute !important;
  top: -10px !important;
  left: 25px !important;
}

.filter-popovers.pricing-screen-filter {
  left: 95px !important;
}

.field-class.escalation-select.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          top: -25px !important;
        }
      }
    }
  }
}

.dropdown-cursor-not-allowed.mat-form-field-disabled {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {

            .mat-select-value,
            .mat-select-arrow-wrapper {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.whitesmoke-new {
  // background-color: #F6F6FA;#FFFFFF
  //background-color: #FFFFFF;
}

.escalation-snack-bar {
  background-color: #ffcc00;
  color: black;
  font-weight: 600;

  margin-bottom: 6rem !important;

  .mat-simple-snackbar {
    justify-content: center !important;
    font-size: 13px !important;
  }
}

.training-cards {
  .cc-card-container {
    height: 100% !important;
  }
}

.release-panel.mat-expansion-panel {
  .mat-expansion-panel-header {
    font-size: 14px;
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      max-height: 60vh !important;
      overflow: auto;
    }
  }
}

.cdk-global-scrollblock {
  overflow-y: inherit !important;

  .modal-open {
    overflow-y: hidden !important;
    overflow-x: auto !important;
  }

  body {
    overflow-y: scroll;
    overflow-x: auto;
  }
}

.waste-disposal-input {
  .cc-input-inner-container {
    margin-bottom: 10px !important;
    height: 48px !important;

    .input-field {
      margin-top: 10px !important;
      padding-top: 10px !important;
    }
  }
}

.app-cat-theme .cc-input-field-disabled,
.app-cat-theme .cc-input-field-disabled .cc-input-field-wrapper,
.app-cat-theme .input-field:disabled,
.app-cat-theme .textarea-field:disabled {
  opacity: 1 !important;
  //background-color: #f5f5f5 !important;
  background: none;
  cursor: not-allowed !important;
}

.quick-popover {
  .arrow {
    display: none !important;
  }

  top: -16px !important;
  left: 0px !important;
  width: 500px !important;
}

.factors-tabs {
  .cc-tab-content {
    display: none;
  }

  .cc-tabset .cc-tab__item--active .cc-tab__item__link {
    font-size: 14px !important;
  }

  .cc-tabset .cc-tab__item .cc-tab__item__link {
    font-weight: 600 !important;
    font-size: 14px;
  }

  .cc-tab--secondary .cc-tab .cc-tab__item {
    border-bottom: none !important;
  }

  .cc-tab--secondary .cc-tab {
    background: none !important;
    //justify-content: space-evenly !important;
    padding-right: 5rem;
  }

  .cc-tabset .cc-tab__item__link--desktop {
    padding: 4px 13px !important;
  }

  .cc-tab__bottom_slider {
    display: none;
  }

  .cc-tabset .cc-tab--fill .cc-tab__item {
    flex: 1 1 auto;
    text-align: center;
    flex-grow: 0 !important;
  }

  .active {
    transition-timing-function: ease-in;
    transition-duration: 0.1s;
    box-shadow: inset 0 -5px 0 -1px rgb(255, 205, 17) !important;
  }
}

.currency-selection.mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;

    .mat-form-field-underline {
      bottom: 0px !important;
    }

    .mat-form-field-flex {
      .mat-form-field-infix {

        // padding-bottom: 0px !important;
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
}

.cross-selection-removal {
  .mat-select-search-inner {
    .mat-select-search-input {
      caret-color: auto !important;
    }
    button:focus {
      outline: 0px;
      
    }
  }
}

span.fa,
div.fa {
  font-family: "FontAwesome" !important;
}

.mat-table {
  font-family: noto-sans, "helvetica neue", sans-serif !important;
}

h3 {
  letter-spacing: 0px !important;
  font-size: 16px;
}

h6 {
  font-size: 16px !important;
}

.app-cat-theme .avg-load .cc-input-field-disabled {
  background: white !important;
}

.overlay-for-filter {
  background-color: rgba(0, 0, 0, 0.7);
}

.cc-drawer__footer {
  height: 72px;
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 11;
  width: 100%;
  border-top: 1px solid #e7e7e7e7;
}

.cc-drawer__header {
  height: 50px;
  border-bottom: 1px solid #e7e7e7e7;
}

.cc-drawer__header .filter-header {
  font-size: 19px !important;
  padding-top: 15px;
  padding-left: 10px;
  display: inline-block;
  font-weight: 700;
}

.cc-drawer__content::-webkit-scrollbar {
  width: 8px;
}

.cc-drawer__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

.app-cat-theme .basic-data-input.cc-input-field {
  .input-field {
    background-color: white !important;
  }

  .cc-input-field-label {
    left: 0px !important;
  }
}

.report-header-style.cc-modal-full-window {
  .cc-modal-container {
    height: 100%;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    display: block;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;

    .cc-modal {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .cc-modal-header {
        background: #ffffff;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(230, 230, 230, 0.6);
        padding: 10px 30px;

        .cc-modal-title {
          color: #212529;
          font-size: 19px;
          font-weight: 700;
          line-height: 28px;
          display: flex;
          align-items: center;
          // letter-spacing: -0.03em;
          padding-right: 15px;
          word-break: break-word;
        }

        .cc-modal-close {
          cursor: pointer;
          margin-left: auto;
        }

        .cc-close-semi {
          width: 12px;
        }

        .cc-close-full {
          width: 27px;
        }
      }

      .cc-modal-content {
        background: #ffffff;
        font-size: 14px;
        color: #78869e;
        padding: 0 30px;
        overflow: scroll;
      }

      .cc-modal-footer {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid rgba(230, 230, 230, 0.6);
        background: #ffffff;
      }

      /* width */
      .cc-modal-content::-webkit-scrollbar {
        width: 8px;
      }

      /* Handle */
      .cc-modal-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #888;
        height: 200px;
        width: 50px;
      }

      /* Handle on hover */
      .cc-modal-content::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.change-background-frequency-for-disabled.cc-input-field-disabled {
  .cc-input-inner-container {
    background-color: #ffffff !important;
    opacity: 0.8 !important;

    .input-field {
      opacity: 0.8 !important;
      background-color: #ffffff !important;
    }
  }

  .cc-input-field-label {
    opacity: 0.6 !important;
  }
}

.mat-cell,
.mat-footer-cell {
  font-size: 13px !important;
}

.form-switch .form-check-label::after {
  top: calc(0.25rem + -2px) !important;
}

.form-check-label::before {
  top: 0 !important;
}


.app-cat-theme .input-field-no-background.cc-input-field {
  .input-field {
    background-color: white !important;
  }
}

.app-cat-theme .cc-input-field {
  .input-field {
    // background-color:  white !important;
    background: none !important;
    font-weight: 400 !important;
  }
}

.app-cat-theme .cc-drawer-container {
  width: 490px;
  position: fixed;
  top: 0;
  bottom: 0;
  background: #ffffff;
  right: 0;
  box-shadow: none !important;
}

.label-see-all {
  font-size: 12px !important;
}

.modified-input-field {
  font-size: 13px !important;
}

.search-box {
  font-size: 13px !important;
}

.floating-input:focus,
.search-box:focus {
  background: none !important;
}

.cc-input-field-disabled {
  .cc-input-inner-container.cc-input-field-disabled {
    background: none !important;

    .input-field:disabled {
      background: none !important;
    }

    .cc-input-field-label {
      background: none !important;
    }
  }
}

.empty-record-text {
  font-size: 1.4rem !important;
}

.app-cat-theme .pricing-section .cc-accordion .cc-accordion-item .cc-panel {
  padding: 7px 0px !important;
  background: #ffffff !important;
  font-size: 13px;
  font-weight: bold;
}

// Estimate Notes Styles
#notesDrawer {
  width: 50%;
}

.app-cat-theme .table-data {
  font-size: 13px !important;
}

.app-cat-theme .cc-table-wrapper-sort .table-body-cell-checkbox {
  padding-right: 17px;
}

.app-cat-theme .cc-table-container {
  margin-top: -14px;
}

.z-index-unset {
  z-index: unset !important;
}

.bulk-action-class {
  z-index: 0 !important;
}

.pm-wizard-table .cdk-header-row {
  border-top: none !important;
  border-bottom: 1px solid #dee2e6 !important;
  padding-bottom: 0.4rem !important;
}

.pm-wizard-table .cdk-row {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: none !important;
}

.app-cat-theme .pm-wizard-table .cc-table-wrapper-sort .table-body-cell {
  height: 40px !important;
}

.ng-tooltip {
  z-index: 0 !important;
}

.pm-wizard-table .cc-table-container {
  overflow-y: hidden !important;
}

.app-cat-theme .cc-message.cc-error {
  box-shadow: none !important;
}

.quick-estimator-detail-content .card,
.summary-section .card {
  border-radius: unset !important;
}

#error-message-container .message-container {
  width: 100%;
}

.dateTimeClass .cc-datepicker .cc-input-field .cc-input-inner-container {
  height: 0px;
  border-bottom: none !important;

  .cc-input-field-label {
    top: -25px !important;
    font-size: 13px !important;
    transform: translate(0, 4px) scale(1) !important;
    left: 0px !important;
    min-width: fit-content;
  }

  .cc-input-field-wrapper {
    .input-field {
      margin: 2px 0 0px;
      font-weight: 400 !important;
      font-size: 14px !important;
    }
  }
}

.dateTimeClass {

  //color: #78869E;
  // margin-top: 40px;
  .cc-input-field-disabled {
    background-color: white !important;
  }
}

.dateTimeClass .cc-datepicker .cc-input-field .cc-input-field-disabled {
  .cc-input-field-wrapper {
    background-color: white !important;

    .input-field {
      background-color: white !important;
    }
  }
}

.calendar-css {
  padding-bottom: 1rem;
}

.reset-button-qc {
  font-size: 12px;
  justify-content: flex-end;
  color: #ffcc11 !important;
  height: 4px;
}

.reset-button-qc .cat-btn-secondary-outline {
  padding: 7px 8px 7px 8px !important;
  height: 33px !important;
}



.quick-estimator-detail-content .card {
  width: 101% !important;
}

.summary-section .card {
  width: 89%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .factors-tabs.set-french {
    .cc-tabset .cc-tab__item__link--desktop {
      padding: 4px 10px !important;
    }

    max-width: 65%;
    flex: 1 0 65%;
  }

  .factors-tabs.flex-55 {
    .cc-tab--secondary .cc-tab {
      flex-wrap: nowrap !important;
    }

    max-width: 53% !important;
    flex: 0 0 53.666667% !important;
    padding-left: 15px;
  }

  .radio-button-section.set-french {
    .checkbox-content .checkbox-container {
      margin: 3px !important;
      top: 0.3rem !important;
    }

    .checkbox-content .sell-label {
      left: 1.3rem;
    }

    .checkbox-content {
      left: 1.57rem !important;
    }

    left: 0.5rem;
  }

  .quick-estimator-detail-content .card {
    width: 103% !important;
  }
}

.app-cat-theme .cc-table-wrapper-sort .table-header:first-child {
  padding-left: 8px !important;
}

.app-cat-theme .cc-table-wrapper-sort .table-data:first-child:not(.table-expandable-row) {
  padding: 0 16px 0 8px !important;
}

.required-star {
  color: #ed1c24 !important;
}

.radio-button-section.set-french {
  .checkbox-content {
    left: 1.8rem;
  }
}

.app-cat-theme .cc-tracker-list .cc-tracker-circle-wrapper .cc-tracker-label {
  width: 150px;
}

.custom-mat-dialog-wg-add {
  max-height: 100vh;
}

.option-disable.mat-option {
  cursor: not-allowed !important;

  .mat-option-pseudo-checkbox {
    cursor: not-allowed;
  }
}

.app-cat-theme .cc-table-wrapper-sort .table-header-row:not(.display-none) {
  z-index: 0 !important;
}

.column-min-width {
  min-width: 160px !important;
}

//RC Component multi select with chips

.rc-component-multi-select-dropdown,
.rc-component-single-select-dropdown,
.rc-component-multi-chip-select {
  .cc-dropdown-wrapper {
    .cc-dropdown-input-wrapper {
      .cc-select-wrapper {
        .cc-placeholder-wrapper {
          .cc-selection-expand {
            .cc-selection-more-label {
              font-size: 12px !important;
            }
          }
          overflow: hidden;
        }
      }
    }
  }

  .cc-dropdown-overlay-list {
    .cc-select-option {
      .cc-dropdown-select-option {
        .cc-option-label {
          .cc-checkbox {
            .cc-checkbox-container {
              .cc-checkbox-inner-container {
                .cc-checkbox-label {
                  font-size: 14px !important;
                }
              }
            }
          }

          .label {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .cc-input-field {
    .cc-input-inner-container {
      .cc-input-field-wrapper {
        .input-field {
          margin-top: 5px !important;
        }
      }
    }
  }
}

.rc-component-single-select-label {
  .cc-dropdown-wrapper {
    .cc-dropdown-input-wrapper {
      .cc-select-wrapper {
        .cc-placeholder-wrapper {
          font-size: 14px;
          overflow: hidden;
        }
      }
    }
  }
}

.input-field-alignment.cc-input-field .cc-input-inner-container {
  .cc-input-field-label {
    width: 100% !important;
  }
}

#copyDrawer {
  width: 70%;
  position: fixed;
  top: 0;
  bottom: 0;
  background: #ffffff;
  right: 0;
}

#copyDrawer1 {
  width: 84%;
  position: fixed;
  top: 0;
  bottom: 0;
  background: #ffffff;
  right: 0;
}
#copyDrawer2 {
  width: 45vw;
  position: fixed;
  top: 0;
  bottom: 0;
  background: #ffffff;
  right: 0;
}

#copyDrawer2 .checkbox-container{
  
display: none !important;
}

#copyDrawerBlock {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #ffffff;
  right: 0;
  width: 455px;
}

.copy-wg-button {
  padding: 0 6px !important;
  justify-content: flex-start;
}

.regional-pricing-dropdown {
  margin-left: 25px;
}

.default-sales-area {
  .cc-dropdown-wrapper {
    .cc-dropdown-input-wrapper {
      .cc-select-wrapper {
        .cc-placeholder-wrapper {
          .cc-placeholder-text {
            margin-top: 1px !important;
          }
        }
      }
    }
  }
}

.cc-dropdown .cc-dropdown-wrapper .cc-dropdown-input-wrapper .cc-select-wrapper .cc-placeholder-wrapper>span .cc-selection-more-label.chips {
  width: 47px;
}

.engine-performance-product .mat-dialog-content {
  display: block;
  margin: 0 -13px;
  padding: 0 28px;
  max-height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.app-cat-theme .global-reload .cc-accordion .cc-accordion-item .cc-panel {
  padding: 7px 0px !important;
  background: #ffffff !important;
  font-size: 13px;
  font-weight: bold;
}

.copy-global-section .cc-drawer__footer {
  height: 72px;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 11;
  width: 100%;
  border-top: 1px solid #e7e7e7e7;
  padding-right: 2rem;
}

.cc-dropdown .cc-dropdown-wrapper .cc-dropdown-input-wrapper .cc-select-wrapper .cc-arrow-wrapper.chips {
  padding-top: 14px !important;
}

.sales-area .cc-dropdown .cc-dropdown-wrapper .cc-dropdown-input-wrapper .cc-select-wrapper .cc-arrow-wrapper {
  padding-top: 4px;
}

.field-class.marketing-org-drop.mat-form-field-should-float {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            height: 17px !important;
          }
        }
      }
    }
  }
}

.confirm-modal-dealer .modal-dialog .modal-content .container-style {
  width: 430px !important;

  .modal-body {
    max-height: 350px;
    overflow-y: auto;
  }
}

.app-cat-theme .pricing-section .cc-accordion .cc-accordion-item .header .cc-accordion-title {
  width: 100% !important;
}

.app-cat-theme .cc-table-wrapper-sort .table-header-cell,
.app-cat-theme .cc-table-wrapper-sort .table-footer-cell,
.app-cat-theme .cc-table-wrapper-sort .table-data {
  line-height: 23px;
}

.app-cat-theme .cc-table-wrapper-sort .table-header-cell-checkbox {
  padding-right: 8px;
  width: 18px;
}

.previewcard1.cc-card-container {
  width: 77% !important;
}

.btn-mr {
  margin-right: 10px;
}

.labor-modified .cc-tab-content {
  padding: 0 !important;
}

.labor-modified .cc-tabset.cc-tab--toggle .cc-tab,
.non-table .cc-tabset.cc-tab--toggle .cc-tab {
  left: 2rem;
}

.non-table .cc-tabset.cc-tab--toggle .cc-tab {
  left: 0.9rem;
}

.global-reload-backdrop {
  z-index: 1000000 !important;
}

.restore-backdrop {
  z-index: 1000000 !important;
}

.quill-editor-no-border .ql-editor.ql-blank::before {
  font-style: normal !important;
}

.field-class.variability-dropdown {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                font-weight: 400 !important;
                font-style: normal !important;
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }
}

.cc-drawer__header .copy-global-header {
  font-size: 18px !important;
  padding-top: 15px;
  padding-left: 10px;
  font-weight: 600;
  color: #43485c;
}

.app-cat-theme .accordion-content .cc-tab--toggle .cc-tab .cc-tab__item--active .cc-tab__item__link {
  color: white !important;
}

.app-cat-theme .accordion-content .cc-tab--toggle .cc-tab .cc-tab__item--active {
  background: #43485c !important;
  border-radius: 3px;
}

.app-cat-theme .accordion-content .cc-tab--toggle .cc-tab__item__link {
  color: black !important;
}

.app-cat-theme .accordion-content .cc-tab--toggle .cc-tab .cc-tab__item {
  background: white;
  border-radius: 3px;
}

.app-cat-theme .accordion-content .cc-tab--toggle .cc-tab {
  background: #ffffff;
  border: 0.4px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
}

.app-cat-theme .generalText.cc-input-field .input-field {
  font-weight: normal !important;
}

.general .field-class .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text {
  font-weight: normal !important;
}

.general {
  .mat-select-trigger {
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: gray !important;
      }
    }
  }
}

.normalFont.field-class .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text {
  font-weight: normal !important;
}

.field-class.normalFont {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                font-weight: normal !important;
              }
            }
          }
        }
      }
    }
  }
}

.btn-space {
  margin-right: 10px;
}

.action-icon-reload {
  background-color: #f6f6fa;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #43485c;
  display: flex;
  width: fit-content;
  height: fit-content;
  vertical-align: middle;
}

.checkbox-container {
  .round-progress-complete {
    border-radius: 50% !important;
    height: 30px !important;
    width: 30px !important;
    margin-top: -15px;
  }

  .round-progress-complete::after {
    left: 11px !important;
    top: 7px !important;
    width: 7px !important;
    height: 12px !important;
  }
}

.btn-mr-12 {
  margin-right: 12px;
}

#error-message-container-advanced .message-container {
  .cc-message-bar-container {
    position: fixed;
    z-index: 200;

    .cc-message {
      margin-right: 33px;
      background-color: #e6f1fa;
      border: 1px solid #0078d6;
      border-radius: 5px;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }

        .message-action-btn {
          .message-action-label {
            color: #0078d6;
          }
        }
      }
    }
  }
}

#error-message-container-deleted .message-container {
  .cc-message-bar-container {
    position: fixed;
    top: 49px;
    z-index: 200;

    .cc-message {
      margin-right: 33px;
      background-color: #e6f1fa;
      border: 1px solid #0078d6;
      border-radius: 5px;
      width: 95%;
      margin-left: -16px;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }

        .message-action-btn {
          .message-action-label {
            color: #0078d6;
          }
        }
      }
    }
  }
}

#error-message-container-alert .message-container {
  .cc-message-bar-container {
    position: fixed;
    top: 49px;
    z-index: 200;

    .cc-message {
      // margin-right: 33px;
     // background: #FFD0D1;
      /* Accent/Red/Medium Red */

      border: 1px solid #DE2222;
      border-radius: 5px;
      width: 91%;
      margin-left: 4rem;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }

        .message-action-btn {
          .message-action-label {
            color: #0078d6;
          }
        }
      }
    }
  }
}

#error-message-container-perf .message-container {
  .cc-message-bar-container {
    position: fixed;
    z-index: 200;

    .cc-message {
      margin-right: 60px;
      background-color: #e6f1fa;
      border: 1px solid #0078d6;
      border-radius: 5px;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }

        .message-action-btn {
          .message-action-label {
            color: #0078d6;
          }
        }
      }
    }
  }
}

#error-message-container-pricing .message-container {
  .cc-message-bar-container {
    padding: 10.12px 14px;

    .cc-message {
      background-color: #e6f1fa;
      border: 1px solid #0078d6;
      border-radius: 5px;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }

        .message-action-btn {
          .message-action-label {
            color: #0078d6;
          }
        }
      }
    }
  }
}

#factor-pending-msg-container .message-container {
  .cc-message-bar-container {
    position: fixed;
    z-index: 200;

    .cc-message {
      margin-right: 33px;
      background-color: #ffe4c2;
      border: 1px solid #e56c00;
      border-radius: 5px;
      box-shadow: none;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }
      }
    }
  }
}

#factor-pending-msg-container-pricing .message-container {
  .cc-message-bar-container {
    padding: 10.12px 14px;

    .cc-message {
      background-color: #ffe4c2;
      border: 1px solid #e56c00;
      border-radius: 5px;
      box-shadow: none;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }
      }
    }
  }
}

#factor-pending-msg-container-perf .message-container {
  .cc-message-bar-container {
    position: fixed;
    z-index: 200;
    padding: 10.12px 30px;

    .cc-message {
      background-color: #ffe4c2;
      border: 1px solid #e56c00;
      border-radius: 5px;
      box-shadow: none;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }
      }
    }
  }
}

#factor-error-msg-container .message-container {
  .cc-message-bar-container {
    position: fixed;
    z-index: 200;

    .cc-message {
      margin-right: 33px;
      background-color: #ffd0d1;
      border: 1px solid #de2222;
      border-radius: 5px;

      .message-content {
        font-size: 14px;
      }
    }
  }
}

#factor-error-msg-container-pricing .message-container {
  .cc-message-bar-container {
    padding: 10.12px 14px;

    .cc-message {
      background-color: #ffd0d1;
      border: 1px solid #de2222;
      border-radius: 5px;

      .message-content {
        font-size: 14px;
      }
    }
  }
}

#factor-error-msg-container-perf .message-container {
  .cc-message-bar-container {
    position: fixed;
    z-index: 200;
    padding: 10.12px 30px;

    .cc-message {
      background-color: #ffd0d1;
      border: 1px solid #de2222;
      border-radius: 5px;

      .message-content {
        font-size: 14px;
      }
    }
  }
}

@font-face {
  font-family: "UniversLTW02-67BoldCn";
  src: url(./assets/fonts/OneTrust.ttf) format("truetype");
}

.travel-popup-compare {
  z-index: 100000 !important;
}

.progress-spinner-modal-compare {
  .modal-dialog {
    .modal-content {
      background-color: unset !important;
    }
  }

  z-index: 100000 !important;
}

@supports (-ms-ime-align: auto) {
  .progress-spinner-modal-compare {
    .modal-dialog {
      .modal-content {
        width: 100%;
        background: none !important;
      }
    }

    z-index: 100000 !important;
  }
}

.travel-popup-compare-backdrop {
  z-index: 100000 !important;
}

.mat-autocomplete-panel {
  z-index: 100001;
}

.custom-modalbox-additional {
  mat-dialog-container {
    padding: 0 !important;
    border-radius: 0px !important;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

.compare-parts-list-eye-icon {
  width: 230px;
  max-height: 45vh;
  overflow-x: hidden;
  z-index: 100000 !important;
  //transform: translate(1030px, 172px) !important;
}

.compare-estimate-parts-list-eye-icon {
  width: 230px;
  max-height: 45vh;
  overflow-x: hidden;
  z-index: 100000 !important;
}

.store-number-popover-parts {
  left: 100px !important;
}

#info-message-container-profile .message-container {
  .cc-message-bar-container {
    padding-top: 1rem;
    z-index: 200;

    .cc-message {
      margin-right: 33px;
      background-color: #e6f1fa;
      border: 1px solid #0078d6;
      border-radius: 5px;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }

        .message-action-btn {
          .message-action-label {
            color: #0078d6;
          }
        }
      }
    }
  }
}

.parts-details {
  .cc-tab-content {
    display: none !important;
  }

  .cc-tabset .cc-tab__item__link {
    font-size: 14px !important;
  }

  .cc-tab__item {
    border-bottom: none !important;

  }


}

.compareEstimateChips {
  .cc-cross-chip {
    margin-left: 20px;
  }
}

.btn-danger-height {
  height: 37px !important;
}

.overflow-hidden-body {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}


.full-screen-parts-table {
  .modal-dialog {
    margin-top: 0px;
    margin-bottom: 0px;

    .modal-content {
      height: 100vh;
      width: 100vw;
    }
  }
}

.full-screen-parts-compare-table {
  z-index: 100000 !important;

  .modal-dialog {
    margin-top: 5px;
    margin-bottom: 0px;

    .modal-content {
      height: 100vh;
      width: 100vw;
    }
  }
}

.cc-tabset.service-parts {
  box-shadow: none;
}

.parts-list-menu {
  .cc-tabset {
    box-shadow: none;
    min-width: 50%;

    .cc-tab-content {
      padding: 0px;
    }

    .cc-tab {
      .cc-tab__item {
        .cc-tab__item__link {
          font-size: 14px;
        }
      }
    }
  }
}

.disable-body-overflow {
  overflow: hidden;
}

.estimate-list-drawer-container {
  height: 100vh;
  overflow: auto;
}

.list-details-accordion-header {
  .cc-accordion-item {
    .header {
      background-color: white;

      .open {
        svg {
          path {
            fill: black !important;
          }
        }
      }
    }

    .cc-panel {
      padding: 16px 39px !important;
      background: white !important;
    }
  }
}

.form-switch .form-check-input {
  background: none !important;
  width: 28px;
  height: 16px;
}

.form-switch .form-check-input::after {
  position: absolute;
  content: "";
  background: black !important;
  margin-top: 1px;
  margin-left: 1px;
  width: 12px;
  height: 12px;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out
}

.form-switch .form-check-input:checked::after {
  content: '';
  transform: translateX(0.75rem);

}

.form-switch {
  position: relative !important;
}

.mat-menu-panel.menu-wraper-notification {
  max-width: 500px;
  width: 450px;
  border-radius: 0px;
  margin-top: 18px;
  margin-right: -60px;
  overflow: hidden;
}

.modal-open {
  overflow: hidden !important;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 2000;
}

.grief-backdrop {
  z-index: 18000 !important
}

.grief-window {
  z-index: 18010 !important
}

.split-btn-copyTo {
  border-radius: 0px !important;
  font-size: 0.875rem;
  transition: background 200ms ease-out, color 200ms ease-out,
    border 200ms ease-out;
  font-weight: bold;
  background-color: white;
  border: 1px solid #78869e !important;

  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        line-height: 0 !important;
        padding: 0px !important;
      }

      color: #43485c;
      font-weight: 600;
      font-size: 12px;
    }

    .mat-button-toggle-button:focus {
      outline: none !important;
    }

    padding: 2px 6px 2px;
    background-color: unset;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    padding: 2px 6px 2px;
    background-color: unset;
  }
}

.split-btn-copyTo:not(:disabled):hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.copy-to-dropdown {
  min-width: 136px !important;
  min-height: 45px !important;

  .mat-menu-content {
    padding: 0px !important;

    .mat-menu-item {
      height: 45px !important;
      line-height: 42px !important;
    }

    .mat-menu-item:focus {
      outline: none !important;
    }

    .mat-menu-item:hover {
      background-color: #f2f2f2;
      font-weight: bold;
    }
  }
}

.copy-to-cc-dropdown {
  .cc-dropdown-wrapper {
    .cc-dropdown-input-wrapper {
      .cc-select-wrapper {
        .cc-placeholder-wrapper {
          font-size: 14px;
        }
      }
    }
  }
}

#estimateListDrawer {
  width: 35%;
}

.split-button-1.action-button-1:hover,
.split-button-2.action-button-2:hover {
  .mat-button-toggle-focus-overlay {
    opacity: 0 !important;
  }
}

.configure-dropdown {
  .cc-dropdown-wrapper {
    .cc-dropdown-input-wrapper {
      .cc-select-wrapper {
        .cc-placeholder-wrapper {
          font-size: 14px;
        }
      }
    }
  }
}

.app-cat-theme .cc-input-inner-container.input-field-with-leading-icon.input-field-with-trailing-icon .input-field {
  padding-right: 20px;
  margin: 0px;
}

.full-screen-popover {
  z-index: 9999;
}

.app-cat-theme .input-field {
  font-size: 14px !important;
}

.app-cat-theme .cc-dropdown-overlay-wrapper .cc-dropdown-overlay .cc-select-option .cc-dropdown-select-option {

  font-size: 14px !important;
}

#error-message-container-locatepart .message-container {
  .cc-message-bar-container {
    position: fixed;
    z-index: 200;
    padding: 0;
    padding-left: 30px;

    .cc-message {
      // margin-right: 33px;
      margin-left: -32px;
      background: #FFFFFF;
      // border: 1px solid #0078d6;
      border-radius: 5px;

      .message-content {
        font-size: 14px;

        .message-data {
          color: #000000;
        }

        .message-action-btn {
          .message-action-label {
            color: #0078d6;
          }
        }
      }
    }
  }
}

.error-message-container-alerta {
  position: fixed;
  width: 88%;
  display: block;
  top: 100px;
  z-index: 999;
  
  .message-container {
    .cc-message-bar-container {
      position: fixed;
      top: 49px;
      z-index: 200;
      .cc-message {
        // margin-right: 33px;
        background: #ffebec;
        /* Accent/Red/Medium Red */
        
        border: 1px solid #DE2222 !important;
        border-radius: 5px;
        width: 91%;
        margin-left: 1rem;
        .message-content {
          font-size: 14px;
          .message-data {
            color: #000000;
          }
          .message-action-btn {
            .message-action-label {
              color: #0078d6;
            }
          }
        }
      }
    }
  }
}

.error-message-container-alertb {
  position: fixed;
  width: 88%;
  display: block;
  top: 100px;
  z-index: 999;
  .message-container {
    .cc-message-bar-container {
      position: fixed;
      top: 49px;
      z-index: 200;
      .cc-message {
        // margin-right: 33px;
        background: #FFE4C2;
        /* Accent/Red/Medium Red */
        
        border: 1px solid #E56C00 !important;
        border-radius: 5px;
        width: 91%;
        margin-left: 1rem;
        .message-content {
          font-size: 14px;
          .message-data {
            color: #000000;
          }
          .message-action-btn {
            .message-action-label {
              color: #0078d6;
            }
          }
        }
      }
    }
  }
}
.error-message-container-alertc {
  position: fixed;
  width: 88%;
  display: block;
  top: 100px;
  z-index: 999;
  .message-container {
    .cc-message-bar-container {
      position: fixed;
      top: 49px;
      z-index: 200;
      .cc-message {
        // margin-right: 33px;
        background:#E6F1FA;
        /* Accent/Red/Medium Red */
        
        border: 1px solid #0078D6 !important;
        border-radius: 5px;
        width: 91%;
        margin-left: 1rem;
        .message-content {
          font-size: 14px;
          .message-data {
            color: #000000;
          }
          .message-action-btn {
            .message-action-label {
              color: #0078d6;
            }
          }
        }
      }
    }
  }
}

#error-message-container-disclaimer .message-container {
  .cc-message-bar-container {
    padding: 10px 10px;

    .cc-message {
      box-shadow: none;

      .close-icon {
        padding: 13px;
        cursor: pointer;
        display: none;
      }
    }
  }
}

.ml-auto {
  margin-left: auto;
}

#completed-message .message-container {
  .cc-message-bar-container {
    padding: 10px 10px;
    position: fixed;
    z-index: 1000;
    top: 25px;

    .cc-message {
      box-shadow: none;
      border: 1px solid green;
      border-radius: 5px;
      background: #C9EED2;

      .close-icon {
        padding: 13px;
        cursor: pointer;
      }
    }
  }
}

#default-part-num-message .message-container {
  .cc-message-bar-container {
    padding: 10px 10px;

    .cc-message {
      box-shadow: none;
      border: 1px solid green;
      border-radius: 5px;
      background: #C9EED2;

      .close-icon {
        padding: 13px;
        cursor: pointer;
      }
    }
  }
}

.performance-fuel-table{
  tfoot {
    z-index: 0 !important;
  }
}
.contigency-eye-icon {
  z-index:5000 !important;
  width: 230px;
  max-height: 45vh;
  overflow-x: hidden;
  //transform: translate(1030px, 172px) !important;
  margin-top: -50px;
}
.intervals-eye-icon {
  width: 230px;
  max-height: 45vh;
  overflow-x: hidden;
 // transform: translate(1030px, 172px) !important;
  margin-left: 90px;
 // margin-top: 90px;
}
.travel-eye-icon {
  width: 230px;
  max-height: 45vh;
  overflow-x: hidden;
//  transform: translate(1030px, 172px) !important;
  margin-left: 90px;
}

.mat-tooltip-panel {
  mat-tooltip-component {
    .multiline-tooltop {
      height: auto;
    }
  }
}

.engine-spec-view-popup, .product-view-popup {
  z-index: 100000;
}

.engine-spec-view-backdrop {
  background-color: #888;
  z-index: 99999 !important;
}
.eye-popover1 {
  top: -30px !important;
  width: 230px;
  max-height: 65vh;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 100 !important;
  padding-top: 10px;

  div.arrow::before {
    top: 31px !important;
  }

  div.arrow::after {
    top: 31px !important;
  }

  #refreshBlock {
    position: fixed;
    top: 0;
    bottom: 0;
    background: #ffffff;
    right: 0;
    width: 700px;
  }
}
.soc-refresh-modify-tab {
  div {
    padding-left: 15px;
  }
}
.soc-data-refresh-error-modal {
  .modal-dialog {
    .modal-content {
      app-soc-data-refresh-error {
        max-width: 500px;
      }
    }
  }
}
.product-upload-status-table {
  .cc-table-wrapper {
    .cc-table-container {
      width: 100%;
    }
  }
}
.basic-data-star {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            .mat-placeholder-required {
              color: #ed1c24;
            }
          }
        }
      }
    }
  }
}

.app-cat-theme .mpe-summary .cc-accordion .cc-accordion-item .cc-panel {
  padding: 10px 15px 10px 30px !important;
  background: #ffffff !important;
  font-size: 13px;
  font-weight: bold;
}

.cws-id-font-margin {
  .cc-dropdown-wrapper {
    .cc-dropdown-input-wrapper {
      .cc-label.cc-label-as-placehoder {
        line-height: 2rem !important;
        font-size: 15.5px !important;
        margin-top: -5px;
      }
    }
  }
  
  .cc-select-option {
    .cc-dropdown-select-option {
      width: 16rem;
      .highlight {
        background: #fff !important;
        &:hover {
          background: #43485c !important;
          color: white !important;
        }
      }
    }
    &:hover {
      .cc-dropdown-select-option {
        .highlight {
          background: #43485c !important;
          color: white !important;
        }
      }
    }
  }
  .cc-input-field .cc-input-inner-container {
    position: relative !important;
    height: 40px !important;
    padding: 0px !important;
    border-bottom: 1px solid #e0e0e0 !important;
    .cc-input-field-wrapper {
      height: 41px;
      min-height: 41px;
      display: block;
      position: relative;
      border-bottom: none;
    }
  }
  .cc-input-inner-container:not(.cc-input-field-disabled).cc-floating-label {
    border-bottom: 1px solid #e0e0e0 !important;
  }
  .cc-input-inner-container.input-field-with-leading-icon {
    .input-field {
      padding: 0px 0px 10px 10px;
    }
    .cc-input-field-wrapper > :first-child {
      position: absolute;
      display: none;
    }
  }
  .cc-select-option.cc-dropdown-select-option-active .cc-dropdown-select-option .label .highlight {
    width: 16rem;
    background: #43485c !important;
    color: #fff;
  }
  .cc-icon.cc-icon-inline {
    background-image: url(./assets/images/CloseIcon-Black.png);
    background-repeat: round;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    svg {
      display: none;
    }
  }
  .cc-input-inner-container.input-field-with-leading-icon.input-field-with-trailing-icon {
    .clear {
      position: absolute;
      bottom: 10px;
      right: 10px;
      transition: none;
    }
    .clear-search {
      height: 15px;
      width: 15px;
      display: inline-block;
      cursor: pointer;
      opacity: 1;
    }
  }
  .cc-input-inner-container.input-field-with-leading-icon.input-field-with-trailing-icon.cc-floating-label {
    .input-field {
      padding: 0px 0px 10px 10px;
    }    
    .clear {
      right: 10px;
      transition: none;
    }
  }
}

.app-cat-theme {
  .cc-dropdown-overlay .cc-dropdown-overlay-list::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .cc-dropdown-overlay-wrapper .no-result {
    padding: 10px 15px 10px 0px;
    font-weight: 400;
    color: #9e9e9e;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(80, 95, 122, 0.3);
    list-style-type: none;
    font-size: 15px;
    margin-left: -20px;
    .rightxt {
      display: none;
    }
  }
  .cc-dropdown-overlay-wrapper:not(.cc-dropdown-overlay-wrapper-2) {
    .cc-input-field:not(.tree-dropdown) {
      .cc-input-inner-container:not(.cc-input-field-disabled):not(.ng-invalid):not(.form-invalid):not(.cc-value-bound).cc-floating-label {
        .cc-input-field-wrapper {
          background: none;
          border-bottom: none;
        }
      }
    }
  } 
}

.required-asterisk-color {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            .mat-placeholder-required {
              color: #ed1c24!important;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.app-cat-theme {
  .cc-dropdown:not(.cc-dropdown-disabled):not(.cc-dropdown-list-visible) .cc-dropdown-wrapper:hover .cc-dropdown-input-wrapper {
    background: unset;
    border: none;
    border-bottom: 2px solid #ffcc00 !important;
  }
  .cc-dropdown .cc-dropdown-wrapper .cc-dropdown-input-wrapper {
    border-bottom: 1px solid #000;
  }
  .cc-dropdown:not(.cc-dropdown-disabled).cc-dropdown-list-visible .cc-dropdown-wrapper {
    .cc-dropdown-input-wrapper {
      border: none;
      background-color: unset;
    } 
    .cc-dropdown-input-wrapper:not(.cc-dropdown-invaild):not(.cc-dropdown-table-variation) {
      box-shadow: 0 2px #ffcc00;
    }
  }
}

.app-cat-theme .cc-input-inner-container.input-field-with-leading-icon .input-field {
  padding-left: 35px;
  padding-top: 8px;
}

.mpe-product-config-input {
	.cc-input-inner-container {
		height: 23px !important;
		.input-field {
      margin: -3px 0px!important;
      padding-bottom: 13px;
		}
	}
}

.mpe-product-quan-input {
	.cc-input-inner-container {
		height: 23px !important;
    border-bottom: 1px dashed !important;
		.input-field {
      margin: -3px 0px!important;
      padding-bottom: 13px;
		}
	}
}

.mpe-product-quan-input-error {
	.cc-input-inner-container {
		height: 23px !important;
    border-bottom: 1px dashed !important;
    color: red;
		.input-field {
      margin: -3px 0px!important;
      padding-bottom: 13px;
		}
	}
}


/* Move this css to duplicate-so-adv.component.scss file - Start */
.col-effectivity, .col-laborHours, .col-job-code, .col-businessGroupCode {
  min-width: 85px;
}

.col-smcs-code {
  min-width: 100px;
}

.col-smcs-desc {
  min-width: 142px;
}

.col-intervals {
  min-width: 98px;
}
/* Move this css to duplicate-so-adv.component.scss file - End */


#tco-table-header {
  .cdk-header-row {
      z-index: 100 !important;
  }

  .action-icons {
    background: #fff;
  }

  .table>:not(caption)>*>* {
    background-color: transparent !important;
  }

  .cc-table-wrapper-two-pointo .table-body-row:not(.table-body-row-nested):not(.self-folding-nested-bg):not(.table-multi-nested-active-expanded):not(.table-self-folding-active):not(.row-hover-disabled):hover {
    background: #E6F4FF !important;
  }

  .cc-table-wrapper-two-pointo .table-body-row:not(.table-body-row-nested):not(.self-folding-nested-bg):not(.table-multi-nested-active-expanded):not(.table-self-folding-active):not(.row-hover-disabled):hover .cdk-cell.sticky-column {
    background: #E6F4FF !important;
  }

  .cc-table-wrapper-two-pointo .table-body-row:not(.table-body-row-nested):not(.self-folding-nested-bg):not(.table-multi-nested-active-expanded):not(.table-self-folding-active):not(.row-hover-disabled):hover .action-icons {
    background: #E6F4FF !important;
  }

}

.tco-customer-list .cc-table-wrapper-two-pointo .cc-table-container:not(.cc-table-selffoldable-container) {
  overflow-x: hidden !important;
}

.estimate-list-selection .table-box-shadow {
  height: 44vh !important;
  max-height: 44vh !important;
}

@media screen and (min-height:576px) {
  .estimate-list-selection .table-box-shadow {
      height: 46vh !important;
      max-height: 46vh !important;
  }
}

@media screen and (min-height:1024px) {
  .estimate-list-selection .table-box-shadow {
      height: 60vh !important;
      max-height: 60vh !important;
  }
}

.view-summary-table {
  .table-box-shadow {
    box-shadow: none !important;
  }

  .table-group-header-row:nth-child(1) {
    top: 0 !important
  }

  .table-group-header span {
    display: block !important;
    margin: 8px 0 6px 0 !important;
  }
}

.ExpectedLifecycleSMU  {
  .cdk-column-rowType {
    background: rgba(51, 155, 224, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(51, 155, 224, 0.2)
  }

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(51, 155, 224, 0.125)
  }
}

.FinancePeriodYears {
  .cdk-column-rowType {
    background: rgba(51, 155, 224, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(51, 155, 224, 0.2)
  }

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(51, 155, 224, 0.125)
  }
}

.ExpectedLifecycleYears  {
  .cdk-column-rowType {
    background: rgba(51, 155, 224,0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(51, 155, 224, 0.2)
  }

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(51, 155, 224, 0.125)
  }
}

.PurchasePrice  {
  .cdk-column-rowType {
    background: rgba(51, 155, 224, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(51, 155, 224, 0.2)
  }

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(51, 155, 224, 0.125)
  }
}

.InterestPaid {
  .cdk-column-rowType {
    background: rgba(51, 155, 224, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(51, 155, 224, 0.2)
  }

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(51, 155, 224, 0.125)
  }
}

.FuelPrice {
  .cdk-column-rowType {
    background: rgba(51, 155, 224, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(51, 155, 224, 0.2)
  }

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(51, 155, 224, 0.125)
  }
}

.EPPPrice {
  .cdk-column-rowType {
    background: rgba(51, 155, 224, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(51, 155, 224, 0.2)
  }

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(51, 155, 224, 0.125)
  }
}

.PlannedRMPrice {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
}

.PlannedRMPriceperSMU {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
}

.DealerRebuildPrice {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
}

.AdditionalScopeforRebuild {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
}

.OpExCapEx {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
}

.MiscellaneousAdjustmentsforRebuild {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
}

.TotalLifecycleExpense {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
}

.LifecycleRMPriceperSMU {
  .cdk-column-rowType {
    background: rgba(255, 204, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 204, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 204, 0, 0.125);
  }
 }

 .Resalevalue {
  .cdk-column-rowType {
    background: rgba(255, 128, 0, 0.25)
  }

  .cdk-column-new_YourEquipment, .cdk-column-new_newEquipment, .cdk-column-new_TotalOandO {
    background: rgba(255, 128, 0, 0.2)
  } 

  .cdk-column-rebuild_YourEquipment, .cdk-column-rebuild_FirstRebuild, .cdk-column-rebuild_TotalOandO {
    background: rgba(255, 128, 0, 0.125);
  }
 }

#view-summary-table .cc-table-wrapper-two-pointo .table-body-row:not(.table-body-row-nested):not(.self-folding-nested-bg):not(.table-multi-nested-active-expanded):not(.table-self-folding-active):not(.row-hover-disabled):hover {
  background: transparent !important;
}